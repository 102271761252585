import { NgModule, APP_BOOTSTRAP_LISTENER, Type, InjectionToken, Inject } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './messages.state';
import { messageReducer } from './messages.reducer';
import { EffectSources, EffectsModule } from '@ngrx/effects';
import { MessagesEffects } from './messages.effects';
import { MessagesStore } from './messages.store';
import { Config } from '@paragondata/ngx-config';

/**
 * Effects wurden erstellet bevor APP_INITIALIZER fertig war.
 * Dies ist ein Workaround, um die Effekte zu bootstrappen.
 * Siehe
 * https://github.com/ngrx/platform/issues/931
 * https://github.com/brandonroberts/effects-issue-example
 */
const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

function bootstrapEffects(effects: Type<unknown>[], sources: EffectSources) {
  return () => {
    effects.forEach((effect) => sources.addEffects(effect));
  };
}

function createInstances(...instances: unknown[]) {
  return instances;
}

function provideBootstrapEffects(effects: Type<unknown>[]) {
  return [
    effects,
    { provide: BOOTSTRAP_EFFECTS, deps: effects, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources, Config],
    },
  ];
}

@NgModule({
  imports: [StoreModule.forFeature(FEATURE_NAME, messageReducer), EffectsModule.forFeature([])],
  providers: [...provideBootstrapEffects([MessagesEffects]), MessagesStore],
})
export class MessagesStoreModule {}
